<template>
	<div class="box">
		<div class="flex title_box">
			<Title :title="$route.query.id ? '修改开票资料' : '添加开票资料'" />
			<div class="but_box">
				<button class="cancel" @click="save('取消')">取消</button>
				<button class="save" @click="PostForm">保存</button>
			</div>
		</div>
		<div class="ct_box">
			<div class="flex" style="margin-bottom: 2rem; align-items: center">
				<div style="width: 12rem">
					<p class="form_title">发票抬头:</p>
				</div>
				<el-input v-model="form.header" placeholder="请填写营业执照上的公司全称"></el-input>
			</div>
			<div class="flex" style="margin-bottom: 2rem; align-items: center">
				<div style="width: 12rem">
					<p class="form_title">发票类型:</p>
				</div>
				<el-radio v-for="(item, index) in type" :key="index" v-model="form.style" :label="item.id">{{ item.title
				}}</el-radio>
			</div>
			<div class="flex" style="margin-bottom: 2rem; align-items: center">
				<div style="width: 12rem">
					<p class="form_title">纳税人识别号:</p>
				</div>
				<div>
					<el-input v-model="form.tax_num" placeholder="请输入纳税人识别号"></el-input>
					<p style="font-size: 1.17rem">
						如果您的营业执照是三证合一或五证合一的证照，请填写统一社会信用代码
					</p>
				</div>
			</div>
			<div class="flex" style="margin-bottom: 2rem; align-items: center">
				<div style="width: 12rem">
					<p class="form_title">营业执照：</p>
				</div>
			<el-upload v-if="!form.logo" accept=".jpg,.png,.gif" class="avatar-uploader" action="/api/common/upload"
					:headers="headerObj" :show-file-list="false" :on-success="handleAvatarSuccess"
					:before-upload="beforeAvatarUpload">

					<i class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
				<div class="flex upload_img" v-if="form.logo">
					<img :src="form.logo" alt="" style="width: 120px;">
					<!-- 删除图标 -->
					<span class="delete_icon el-icon-delete" @click="beforeMove"></span>
				</div>
				<p style="margin: 4rem 0 0 2rem">jpg、png、gif</p>
			</div>
			<div class="flex" style="margin-bottom: 2rem; align-items: center">
				<div style="width: 12rem">
					<p class="form_title">注册场所地址：</p>
				</div>
				<el-input v-model="form.register_address" placeholder="请填写您营业执照上的地址"></el-input>
			</div>
			<div class="flex" style="margin-bottom: 2rem; align-items: center">
				<div style="width: 12rem">
					<p class="form_title">注册固定电话：</p>
				</div>
				<el-input v-model="form.register_phone" placeholder="请填写您公司有效的联系电话"></el-input>
			</div>
			<div class="flex" style="margin-bottom: 2rem; align-items: center">
				<div style="width: 12rem">
					<p class="form_title">基本开户账号：</p>
				</div>
				<el-input v-model="form.bank_name" placeholder="请填写您开户许可证上的银行账号"></el-input>
			</div>
			<div class="flex" style="margin-bottom: 2rem; align-items: center">
				<div style="width: 12rem">
					<p class="form_title">快递收件人：</p>
				</div>
				<div>
					<el-input v-model="form.recipient" placeholder="请填写快递收件人"></el-input>
					<p style="font-size: 1.17rem">
						发票将快递给您，请填写有效收件人信息
					</p>
				</div>
			</div>
			<div class="flex" style="margin-bottom: 2rem; align-items: center">
				<div style="width: 12rem">
					<p class="form_title">快递地址：</p>
				</div>
				<el-input v-model="form.address" placeholder="请填写快递地址"></el-input>
			</div>
			<div class="flex" style="margin-bottom: 2rem; align-items: center">
				<div style="width: 12rem">
					<p class="form_title">收件人电话：</p>
				</div>
				<el-input v-model="form.tel" placeholder="请填写收件人电话"></el-input>
			</div>
			<div class="flex" style="margin-bottom: 2rem; align-items: center">
				<div style="width: 12rem">
					<p class="form_title">收件人邮箱：</p>
				</div>
				<el-input v-model="form.email" placeholder="请填写收件人邮箱"></el-input>
			</div>
		</div>
	</div>
</template>

<script>
import Title from '../../components/UserInfoTitle.vue'
import { mapState } from 'vuex'
export default {
	components: {
		Title,
	},
	computed:{
		...mapState(['ApplyForInvoiceTitle'])
	},
	mounted() {
		this.getList()
		this.curlGet('/api/tax').then(res => {
			console.log(res);
			if (res.data.code) {
				this.type = res.data.data
				this.form.style = res.data.data[0].id
				// console.log(this.style);
			}
		})
	},
	data() {
		return {
			type: [],
			form: {
				header: '',
				style: '',
				tax_num: '', //税务登记处
				logo: '',//营业执照
				bank_name: '', //开户银行名称
				bank_num: '',//基本开户账号
				register_address: '', //注册场所地址
				register_phone: '', //注册固定电话
				recipient: '',//收件人
				address: '',
				tel: '',
				email: ''

			},
			headerObj: {
				'token': this.$user_info.token
			},
		}
	},
	methods: {
		save(item) {
			if (item == '取消') {
				this.$router.go(-1)
			} else {
			}
		},
		// 
		getList() {
			if (this.$route.query.id) {
				this.curlGet('/api/users/invoice_info/detail', {
					id: this.$route.query.id
				}).then(res => {
					console.log(res, '-res-');
					if (res.data.code) {
						this.form = {
							header: res.data.data.header,
							style: res.data.data.style,
							tax_num: res.data.data.tax_num, //税务登记处
							logo: res.data.data.logo,//营业执照
							bank_name: res.data.data.bank_name, //开户银行名称
							bank_num: res.data.data.bank_num,//基本开户账号
							register_address: res.data.data.register_address, //注册场所地址
							register_phone: res.data.data.register_phone, //注册固定电话
							recipient: res.data.data.recipient,//收件人
							address: res.data.data.address,
							tel: res.data.data.tel,
							email: res.data.data.email
						}
					}
				}).then(res => {
					console.log(res);
				})
			}
		},
		PostForm() {
			// if (this.form.header == '') {
			// 	return this.$message({
			// 		type: 'warning',
			// 		message: '请输入发票抬头'
			// 	});
			// }
			// if (this.form.style == '') {
			// 	return this.$message({
			// 		type: 'warning',
			// 		message: '请选择发票类型'
			// 	});
			// }
			// if (this.form.tax_num == '') {
			// 	return this.$message({
			// 		type: 'warning',
			// 		message: '请输入税务登记号'
			// 	});
			// }
			// if (this.form.logo == '') {
			// 	return this.$message({
			// 		type: 'warning',
			// 		message: '请选择公司logo'
			// 	});
			// }
			// if (this.form.bank_name == '') {
			// 	return this.$message({
			// 		type: 'warning',
			// 		message: '请输入开户银行名称'
			// 	});
			// }
			// if (this.form.bank_num == '') {
			// 	return this.$message({
			// 		type: 'warning',
			// 		message: '请输入基本开户账号'
			// 	});
			// }
			// if (this.form.register_address == '') {
			// 	return this.$message({
			// 		type: 'warning',
			// 		message: '请输入注册场所地址'
			// 	});
			// }
			// if (this.form.register_phone == '') {
			// 	return this.$message({
			// 		type: 'warning',
			// 		message: '请输入注册固定电话'
			// 	});
			// }
			// if (this.form.recipient == '') {
			// 	return this.$message({
			// 		type: 'warning',
			// 		message: '请输入收件人'
			// 	});
			// }
			// if (this.form.address == '') {
			// 	return this.$message({
			// 		type: 'warning',
			// 		message: '请输入收货地址'
			// 	});
			// }
			// if (this.form.tel == '') {
			// 	return this.$message({
			// 		type: 'warning',
			// 		message: '请输入手机号'
			// 	});
			// }
			// if (this.form.email == '') {
			// 	return this.$message({
			// 		type: 'warning',
			// 		message: '请输入有效的邮箱地址'
			// 	});
			// }
			if (this.$route.query.id) {
				this.form.id = this.$route.query.id
			}
			this.curlPost('/api/users/invoice_info/save', this.form).then(res => {
				console.log(res);
				if (res.data.code) {
					this.$message({
						type: 'success',
						message: '添加成功'
					});
					this.$store.state.ApplyForInvoiceTitle = '申请发票'
					this.$router.push('/user/ApplyForInvoice/ApplyForInvoiceData')
				} else {
					this.$message({
						type: 'warning',
						message: res.data.msg
					});
				}
			})
		},
		beforeMove() {
			this.$confirm('是否删除', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.form.logo = ''
				this.$message({
					type: 'success',
					message: '已删除'
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消'
				});
			});
		},
		handleAvatarSuccess(res, file) {
			console.log(res);
			this.fullscreenLoading = true
			this.dialogImageUrl = res.data.fullurl

			this.form.logo = `http://login.qixuantong.vip${res.data.url}`
			console.log(this.form.logo);
			setTimeout(() => {
				this.fullscreenLoading = false
			}, 500)

		},
		beforeAvatarUpload(file) {
			let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);

			const extension = testmsg === "jpg" || testmsg === "gif" || testmsg === "png";
			const isLt2M = file.size / 1024 / 1024 < 2

			if (!extension) {
				this.$message.error('上传图片类型只能是 JPG,PNG,GIF格式!')
			}
			if (!isLt2M) {
				this.$message.error('上传图片大小不能超过 2MB!')
			}
			return extension && isLt2M
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../scss/AddInvoice.scss';
</style>